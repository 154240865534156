<template>
  <b-modal
    id="pay-branches-modal"
    centered
    hide-footer
    size="lg"
    @hide="hideModal"
    @show="openModal"
  >
    <template #modal-header>
      <div class="header-container">
        <img
          class="gradient"
          src="/img/icons/settings/gradient.svg"
          alt=""
        />
        <div class="title-block d-flex justify-content-between w-100">
          <h5 class="title">Оплатите подписку</h5>
          <b-button
            class="close"
            @click="hideModal"
          >
            <img
              src="/img/icons/modal_close.svg"
              alt=""
            />
          </b-button>
        </div>

        <div class="info-container">
          <div class="info-container__text">
            Чтобы продолжить пользоваться всеми функциями сервиса - скачайте счёт выбранного тарифа и оплатите его
          </div>
          <img
            class="info-container__img"
            src="/img/icons/settings/image-logo.svg"
            alt=""
          />
        </div>
      </div>
    </template>
    <div class="modal-body">
      <div class="d-flex">
        <div
          class="select-block"
          style="margin-right: 16px"
        >
          <div class="select-block__lable">Юрлицо плательщик</div>
          <i-select v-model="entity">
            <i-option
              v-for="(item, index) of entitiesList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </i-select>
          <div
            v-if="isError"
            class="select-block__error-text"
          >
            Выбирите плательщика!
          </div>
        </div>
        <div class="select-block">
          <div class="select-block__lable">Функционал</div>
          <i-select
            v-model="modules"
            :max-tag-placeholder="maxTagPlaceholder"
            :max-tag-count="1"
            multiple
          >
            <i-option
              v-for="(item, index) of modulesList"
              :key="index"
              :disabled="item.disabled"
              :value="item.id"
              :label="item.name"
            >
              <div class="d-flex justify-content-between">
                <div>
                  {{ item.name }}
                </div>
                <div>{{ item.price + ' ' }}₽</div>
              </div>
            </i-option>
          </i-select>
          <div
            v-if="isError"
            class="select-block__error-text"
          >
            Выбирите плательщика!
          </div>
        </div>
      </div>
      <div class="pay-container">
        <div
          class="pay-item cursor"
          @click="setPay(1)"
        >
          <div class="pay-item__month">1 месяц</div>
          <div class="pay-item__benefit">0%</div>
          <div class="pay-item__sum">{{ formatMoney(getSumOneMonth) }} ₽</div>
          <div class="pay-item__link">Скачать счёт</div>
        </div>

        <div
          class="pay-item cursor"
          @click="setPay(3)"
        >
          <div class="pay-item__month">3 месяца</div>
          <div class="pay-item__benefit">5%</div>
          <div class="pay-item__sum">{{ formatMoney(getSumThreeMonth) }} ₽</div>
          <div class="pay-item__link">Скачать счёт</div>
        </div>

        <div
          class="pay-item cursor"
          @click="setPay(6)"
        >
          <div class="pay-item__month">6 месяцев</div>
          <div class="pay-item__benefit">10%</div>
          <div class="pay-item__sum">{{ formatMoney(getSumSixMonth) }} ₽</div>
          <div class="pay-item__link">Скачать счёт</div>
        </div>

        <div
          class="pay-item"
          @click="setPay(12)"
        >
          <div class="pay-item__month">1 год</div>
          <div class="pay-item__benefit">20%</div>
          <div class="pay-item__sum">{{ formatMoney(getSumOneYear) }} ₽</div>
          <div class="pay-item__link">Скачать счёт</div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { print_html } from '@/utils/print'
  import { http } from '@/http/http'
  import { formatMoney } from '@/utils/formatMoney'
  export default {
    name: 'PayBranchesModal',

    props: {
      branches: {
        type: Array,
        default: () => []
      }
    },

    apollo: {
      Entities: {
        query: require('../../analytics/gql/getEntities.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.entitiesList = data?.Entities ?? []
          if (this.entitiesList.length === 1) this.entity = this.entitiesList[0].id
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {
        entity: '',
        modules: ['base', 'mercury', 'egais', 'crptech', 'autorder', 'payment-plan'],
        entitiesList: [],
        modulesList: [
          {
            id: 'base',
            name: 'Базовый функционал',
            disabled: true,
            price: 300
          },
          {
            id: 'mercury',
            name: 'МЕРКУРИЙ',
            price: 500
          },
          {
            id: 'egais',
            name: 'ЕГАИС',
            price: 500
          },
          {
            id: 'crptech',
            name: 'Честный Знак',
            price: 500
          },
          {
            id: 'autorder',
            name: 'Автозаказ',
            price: 500
          },
          {
            id: 'payment-plan',
            name: 'План платежей',
            price: 500
          }
        ],
        branchesList: [],
        isError: false
      }
    },

    computed: {
      getSumOneMonth() {
        let sum = 0
        this.modules.forEach((el) => {
          const module = this.modulesList.find((md) => el === md.id)
          sum += module.price
        })
        return sum * this.branchesList.length
      },
      getSumThreeMonth() {
        let sum = 0
        this.modules.forEach((el) => {
          const module = this.modulesList.find((md) => el === md.id)
          sum += module.price
        })
        return sum * this.branchesList.length * 3 * 0.95
      },
      getSumSixMonth() {
        let sum = 0
        this.modules.forEach((el) => {
          const module = this.modulesList.find((md) => el === md.id)
          sum += module.price
        })
        return sum * this.branchesList.length * 6 * 0.9
      },
      getSumOneYear() {
        let sum = 0
        this.modules.forEach((el) => {
          const module = this.modulesList.find((md) => el === md.id)
          sum += module.price
        })
        return sum * this.branchesList.length * 12 * 0.8
      }
    },

    methods: {
      maxTagPlaceholder(num) {
        return 'Еще ' + num
      },
      formatMoney(value) {
        return formatMoney(value, 0)
      },
      openModal() {
        this.branchesList = JSON.parse(JSON.stringify(this.branches))
      },

      hideModal() {
        this.$bvModal.hide('pay-branches-modal')
        this.branchesList = []
      },

      async setPay(month) {
        if (this.entity === '') return (this.isError = true)
        else this.isError = false

        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../gql/DownloadSubscriptionPayment.gql'),
            variables: {
              input: {
                entity: this.entity,
                month: month,
                branches: this.branchesList,
                modules: this.modules.length === this.modulesList.length ? ['all'] : this.modules
              }
            }
          })
          this.printHtml(data?.DownloadSubscriptionPayment?.link)
          this.$noty.show(`Успешно`)
          this.hideModal()
        } catch (err) {
          console.log(err)
          this.$noty.error('Ошибка')
        }
      },

      async printHtml(link) {
        try {
          const { data } = await http.get(link)
          if (data) {
            print_html(data)
          } else {
            this.$noty.show('Ошибка печати')
          }
        } catch (e) {
          console.error(e)
          this.$noty.show('Ошибка печати')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .gradient {
    position: absolute;
    top: -25px;
    left: 20px;
  }
  :deep .modal-footer button {
    width: fit-content;
  }
  :deep .modal-header {
    margin-bottom: 0px !important;
  }
  .header-container {
    position: relative;
    width: 100%;
    .title-block {
      margin-bottom: 16px;
      .title {
        margin: 0;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #313131;
      }
    }
  }

  .modal-body {
    padding: 0;
    margin-bottom: 32px;
  }

  .info-container {
    display: flex;

    &__text {
      width: 430px;
      color: var(--Text-primary---gray700, #313131);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &__img {
      position: relative;
      top: -40px;
      right: -40px;
    }
  }

  .select-block {
    width: 300px;
    margin-bottom: 22px;

    &__lable {
      color: var(--Text-primary---gray700, #313131);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 3px;
    }

    &__error-text {
      color: red;
    }
  }

  .pay-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .pay-item {
    width: 165px;
    padding: 22px;
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

    &__month {
      color: var(--Text-primary---gray700, #313131);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &__benefit {
      color: var(--Gray-gray700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 12px;
      margin-bottom: 37px;
    }

    &__sum {
      color: var(--Text-primary---gray700, #313131);
      font-size: 24.901px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }

    &__link {
      color: var(--Text-system---blue400, #00a3ff);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 10px;
    }
  }
</style>
