var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-block"
  }, [_c('change-name-group-branch-modal', {
    attrs: {
      "id_group": _vm.idGroup
    }
  }), _c('pay-branches-modal', {
    attrs: {
      "branches": _vm.selected
    }
  }), _c('div', {
    staticClass: "table-block__content"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('resizable-table', {
    ref: "table_branches",
    staticClass: "table-branches",
    attrs: {
      "table_name": "table_branches",
      "inside_card": false,
      "items": _vm.items,
      "default_fields": _vm.fields,
      "empty_text": "Пусто",
      "resizable": true,
      "busy": _vm.isLoading
    },
    on: {
      "row-click": _vm.rowClick
    },
    scopedSlots: _vm._u([{
      key: "body_id",
      fn: function (_ref) {
        var _vm$selected;

        var item = _ref.item;
        return [_c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.some(function (el) {
              return el === (item === null || item === void 0 ? void 0 : item.id);
            }),
            "disabled": _vm.checkboxType === null ? false : _vm.checkboxType !== (item === null || item === void 0 ? void 0 : item.__typename),
            "position": "center"
          },
          on: {
            "change": function (val) {
              return _vm.addSelected(val, item);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_name",
      fn: function (data) {
        var _data$item, _data$item2, _data$item3, _data$item4, _data$item5, _data$item6;

        return [_c('div', {
          staticClass: "table-name-block",
          class: {
            'child-block': data === null || data === void 0 ? void 0 : (_data$item = data.item) === null || _data$item === void 0 ? void 0 : _data$item.child
          }
        }, [(data === null || data === void 0 ? void 0 : (_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : _data$item2.__typename) === 'BranchClassifModel' ? _c('img', {
          staticClass: "mr-2",
          class: {
            'icon-triangle-no-active': !(data !== null && data !== void 0 && (_data$item3 = data.item) !== null && _data$item3 !== void 0 && _data$item3.isOpenGroup),
            'icon-triangle-active': data === null || data === void 0 ? void 0 : (_data$item4 = data.item) === null || _data$item4 === void 0 ? void 0 : _data$item4.isOpenGroup
          },
          attrs: {
            "src": "/img/icons//settings/triangle.svg",
            "alt": ""
          },
          on: {
            "click": function ($event) {
              return _vm.opemGroup(data === null || data === void 0 ? void 0 : data.item, data === null || data === void 0 ? void 0 : data.index);
            }
          }
        }) : _vm._e(), (data === null || data === void 0 ? void 0 : (_data$item5 = data.item) === null || _data$item5 === void 0 ? void 0 : _data$item5.__typename) === 'BranchClassifModel' ? _c('img', {
          staticClass: "mr-2",
          attrs: {
            "src": "/img/icons//settings/icon_group.svg",
            "alt": ""
          }
        }) : _vm._e(), (data === null || data === void 0 ? void 0 : (_data$item6 = data.item) === null || _data$item6 === void 0 ? void 0 : _data$item6.__typename) === 'Branch' ? _c('img', {
          staticClass: "mr-2",
          attrs: {
            "src": "/img/icons//settings/icon_branch.svg",
            "alt": ""
          }
        }) : _vm._e(), _c('div', [_vm._v(_vm._s(data.item.name))])])];
      }
    }, {
      key: "body_address",
      fn: function (_ref2) {
        var _item$address, _item$address2, _item$address3, _item$address4, _item$address5, _item$address6, _item$address7, _item$address8, _item$address9, _item$address10;

        var item = _ref2.item;
        return [_c('div', {
          staticClass: "table-addres-block"
        }, [item !== null && item !== void 0 && (_item$address = item.address) !== null && _item$address !== void 0 && _item$address.country ? _c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$address2 = item.address) === null || _item$address2 === void 0 ? void 0 : _item$address2.country))]) : _vm._e(), item !== null && item !== void 0 && (_item$address3 = item.address) !== null && _item$address3 !== void 0 && _item$address3.city ? _c('div', [_vm._v(_vm._s(`, ${item === null || item === void 0 ? void 0 : (_item$address4 = item.address) === null || _item$address4 === void 0 ? void 0 : _item$address4.city}`))]) : _vm._e(), item !== null && item !== void 0 && (_item$address5 = item.address) !== null && _item$address5 !== void 0 && _item$address5.street ? _c('div', [_vm._v(_vm._s(`, ${item === null || item === void 0 ? void 0 : (_item$address6 = item.address) === null || _item$address6 === void 0 ? void 0 : _item$address6.street}`))]) : _vm._e(), item !== null && item !== void 0 && (_item$address7 = item.address) !== null && _item$address7 !== void 0 && _item$address7.house ? _c('div', [_vm._v(_vm._s(`, ${item === null || item === void 0 ? void 0 : (_item$address8 = item.address) === null || _item$address8 === void 0 ? void 0 : _item$address8.house}`))]) : _vm._e(), item !== null && item !== void 0 && (_item$address9 = item.address) !== null && _item$address9 !== void 0 && _item$address9.apartment ? _c('div', [_vm._v(_vm._s(`, ${item === null || item === void 0 ? void 0 : (_item$address10 = item.address) === null || _item$address10 === void 0 ? void 0 : _item$address10.apartment}`))]) : _vm._e()])];
      }
    }, {
      key: "body_cancel_date",
      fn: function (_ref3) {
        var _item$subscription, _item$subscription2;

        var item = _ref3.item;
        return [_c('div', [_vm._v(" " + _vm._s((_item$subscription = item.subscription) !== null && _item$subscription !== void 0 && _item$subscription.cancel_date ? _vm.formatDate(new Date((_item$subscription2 = item.subscription) === null || _item$subscription2 === void 0 ? void 0 : _item$subscription2.cancel_date)) : '') + " ")])];
      }
    }])
  }), _c('custom-navbar', {
    staticClass: "table-navbar",
    attrs: {
      "items": _vm.selected,
      "item_name": _vm.checkboxType === null ? 'Позиция' : _vm.checkboxType === 'Branch' ? 'Магазин' : 'Группа'
    },
    on: {
      "clear": _vm.clearSelected
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_vm.checkboxType === 'BranchClassifModel' ? _c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.deleteGroup
          }
        }, [_vm._v(" Удалить группу ")]) : _vm._e(), _vm.checkboxType === 'BranchClassifModel' && _vm.selected.length === 1 ? _c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.changeNameGroup
          }
        }, [_vm._v(" Переименовать группу ")]) : _vm._e(), _vm.checkboxType === 'Branch' ? _c('b-dropdown', {
          staticClass: "navbar-dropdown",
          attrs: {
            "dropup": "",
            "no-caret": "",
            "variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_vm._v("Добавить в группу")];
            },
            proxy: true
          }], null, false, 2309872193)
        }, [_vm._l(_vm.classifList, function (item) {
          return _c('b-dropdown-item', {
            key: item.id,
            on: {
              "click": function ($event) {
                return _vm.addInGroup(item.id);
              }
            }
          }, [_c('div', [_vm._v(_vm._s(item.name))])]);
        }), _c('b-dropdown-item', {
          on: {
            "click": _vm.createGroup
          }
        }, [_c('div', {
          staticClass: "btn-add-group"
        }, [_c('img', {
          staticClass: "mr-2",
          attrs: {
            "src": "/img/icons//settings/Plus.svg",
            "alt": ""
          }
        }), _c('div', [_vm._v("Создать")])])])], 2) : _vm._e(), _vm.checkboxType === 'Branch' ? _c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.delFromGroup
          }
        }, [_vm._v(" Убрать из группы ")]) : _vm._e(), _vm.checkboxType === 'Branch' ? _c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.openPayModal
          }
        }, [_vm._v(" Оплата ")]) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }