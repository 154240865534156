<template>
  <b-modal
    id="change-name-group-branch-modal"
    centered
    hide-footer
    size="sm"
  >
    <template #modal-header>
      <div class="header-container">
        <div class="header-container__block-title">
          <h5 class="title">Название группы</h5>
        </div>
      </div>
    </template>

    <div class="modal-body">
      <div class="modal-content">
        <b-form-input
          v-model="nameGroup"
          type="text"
          placeholder="Введите название"
        />
        <div class="modal-content__buttons-block">
          <div class="b-action-buttons">
            <b-button
              variant="primary"
              class="cursor"
              @click="addGroup"
            >
              Сохранить
            </b-button>
            <b-button
              variant="outline-primary"
              class="cursor ml-3"
              @click="close"
            >
              Отмена
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { EventBus } from '@/main'
  export default {
    name: 'ChangeNameGroupBranchModal',

    props: {
      id_group: {
        type: String,
        default: null
      }
    },

    data() {
      return {
        nameGroup: ''
      }
    },

    methods: {
      close() {
        this.$bvModal.hide('change-name-group-branch-modal')
      },
      async addGroup() {
        if (this.nameGroup === '') return this.$noty.error(`Введите название!`)
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/CreateBranchClassif.gql'),
            variables: {
              input: {
                id: this.id_group,
                name: this.nameGroup
              }
            }
          })
          this.$noty.show(`Успешно!`)
          this.nameGroup = ''
          EventBus.$emit('refetchBranchClassif')
          this.close()
        } catch (err) {
          this.$noty.error(`Что-то пошло не так!`)
          console.log(err)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .modal-header {
    margin-bottom: 0;
    margin-top: 22px;
  }

  :deep .modal-dialog {
    max-width: 420px;
  }

  :deep .modal-body {
    padding-left: 11px !important;
    padding-right: 11px !important;
  }

  :deep .modal-content {
    padding-top: 0px !important;
  }
  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    color: var(--text-primary-gray-700, #313131);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 18px;
  }

  .modal-content {
    width: 100%;

    &__text {
      color: var(--text-primary-gray-700, #313131);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
    }

    &__buttons-block {
      margin-top: 18px;
      margin-bottom: 22px;
    }
  }
</style>
