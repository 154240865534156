var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "pay-branches-modal",
      "centered": "",
      "hide-footer": "",
      "size": "lg"
    },
    on: {
      "hide": _vm.hideModal,
      "show": _vm.openModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('div', {
          staticClass: "header-container"
        }, [_c('img', {
          staticClass: "gradient",
          attrs: {
            "src": "/img/icons/settings/gradient.svg",
            "alt": ""
          }
        }), _c('div', {
          staticClass: "title-block d-flex justify-content-between w-100"
        }, [_c('h5', {
          staticClass: "title"
        }, [_vm._v("Оплатите подписку")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": _vm.hideModal
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])], 1), _c('div', {
          staticClass: "info-container"
        }, [_c('div', {
          staticClass: "info-container__text"
        }, [_vm._v(" Чтобы продолжить пользоваться всеми функциями сервиса - скачайте счёт выбранного тарифа и оплатите его ")]), _c('img', {
          staticClass: "info-container__img",
          attrs: {
            "src": "/img/icons/settings/image-logo.svg",
            "alt": ""
          }
        })])])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "select-block",
    staticStyle: {
      "margin-right": "16px"
    }
  }, [_c('div', {
    staticClass: "select-block__lable"
  }, [_vm._v("Юрлицо плательщик")]), _c('i-select', {
    model: {
      value: _vm.entity,
      callback: function ($$v) {
        _vm.entity = $$v;
      },
      expression: "entity"
    }
  }, _vm._l(_vm.entitiesList, function (item, index) {
    return _c('i-option', {
      key: index,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1), _vm.isError ? _c('div', {
    staticClass: "select-block__error-text"
  }, [_vm._v(" Выбирите плательщика! ")]) : _vm._e()], 1), _c('div', {
    staticClass: "select-block"
  }, [_c('div', {
    staticClass: "select-block__lable"
  }, [_vm._v("Функционал")]), _c('i-select', {
    attrs: {
      "max-tag-placeholder": _vm.maxTagPlaceholder,
      "max-tag-count": 1,
      "multiple": ""
    },
    model: {
      value: _vm.modules,
      callback: function ($$v) {
        _vm.modules = $$v;
      },
      expression: "modules"
    }
  }, _vm._l(_vm.modulesList, function (item, index) {
    return _c('i-option', {
      key: index,
      attrs: {
        "disabled": item.disabled,
        "value": item.id,
        "label": item.name
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between"
    }, [_c('div', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', [_vm._v(_vm._s(item.price + ' ') + "₽")])])]);
  }), 1), _vm.isError ? _c('div', {
    staticClass: "select-block__error-text"
  }, [_vm._v(" Выбирите плательщика! ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "pay-container"
  }, [_c('div', {
    staticClass: "pay-item cursor",
    on: {
      "click": function ($event) {
        return _vm.setPay(1);
      }
    }
  }, [_c('div', {
    staticClass: "pay-item__month"
  }, [_vm._v("1 месяц")]), _c('div', {
    staticClass: "pay-item__benefit"
  }, [_vm._v("0%")]), _c('div', {
    staticClass: "pay-item__sum"
  }, [_vm._v(_vm._s(_vm.formatMoney(_vm.getSumOneMonth)) + " ₽")]), _c('div', {
    staticClass: "pay-item__link"
  }, [_vm._v("Скачать счёт")])]), _c('div', {
    staticClass: "pay-item cursor",
    on: {
      "click": function ($event) {
        return _vm.setPay(3);
      }
    }
  }, [_c('div', {
    staticClass: "pay-item__month"
  }, [_vm._v("3 месяца")]), _c('div', {
    staticClass: "pay-item__benefit"
  }, [_vm._v("5%")]), _c('div', {
    staticClass: "pay-item__sum"
  }, [_vm._v(_vm._s(_vm.formatMoney(_vm.getSumThreeMonth)) + " ₽")]), _c('div', {
    staticClass: "pay-item__link"
  }, [_vm._v("Скачать счёт")])]), _c('div', {
    staticClass: "pay-item cursor",
    on: {
      "click": function ($event) {
        return _vm.setPay(6);
      }
    }
  }, [_c('div', {
    staticClass: "pay-item__month"
  }, [_vm._v("6 месяцев")]), _c('div', {
    staticClass: "pay-item__benefit"
  }, [_vm._v("10%")]), _c('div', {
    staticClass: "pay-item__sum"
  }, [_vm._v(_vm._s(_vm.formatMoney(_vm.getSumSixMonth)) + " ₽")]), _c('div', {
    staticClass: "pay-item__link"
  }, [_vm._v("Скачать счёт")])]), _c('div', {
    staticClass: "pay-item",
    on: {
      "click": function ($event) {
        return _vm.setPay(12);
      }
    }
  }, [_c('div', {
    staticClass: "pay-item__month"
  }, [_vm._v("1 год")]), _c('div', {
    staticClass: "pay-item__benefit"
  }, [_vm._v("20%")]), _c('div', {
    staticClass: "pay-item__sum"
  }, [_vm._v(_vm._s(_vm.formatMoney(_vm.getSumOneYear)) + " ₽")]), _c('div', {
    staticClass: "pay-item__link"
  }, [_vm._v("Скачать счёт")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }