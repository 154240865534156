<template>
  <div class="table-block">
    <change-name-group-branch-modal :id_group="idGroup" />
    <pay-branches-modal :branches="selected" />
    <div class="table-block__content">
      <!-- <div class="search-block">
        <b-form
          class="pos-r"
          @submit.prevent="handlerSearch"
        >
          <img
            class="search-block__img"
            src="/img/icons/contract/search.svg"
            alt=""
          />

          <b-form-input
            v-model="search"
            type="text"
            class="search-block__input"
            placeholder="Поиск"
          />
          <b-button
            v-if="search?.length > 0"
            class="search-block__btn-clear-search-str"
            @click.prevent="clear"
          />
        </b-form>

        <b-dropdown
          class="ml-3"
          no-caret
          variant="none"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/settings.svg"
                alt=""
              />
            </div>
          </template>
          <b-dropdown-item @click="showSortModal">Изменить столбцы </b-dropdown-item>
        </b-dropdown>
      </div> -->

      <div class="table">
        <resizable-table
          ref="table_branches"
          class="table-branches"
          table_name="table_branches"
          :inside_card="false"
          :items="items"
          :default_fields="fields"
          empty_text="Пусто"
          :resizable="true"
          :busy="isLoading"
          @row-click="rowClick"
        >
          <template #body_id="{ item }">
            <div @click.stop>
              <b-form-checkbox
                :checked="selected?.some((el) => el === item?.id)"
                :disabled="checkboxType === null ? false : checkboxType !== item?.__typename"
                position="center"
                @change="(val) => addSelected(val, item)"
              />
            </div>
          </template>

          <template #body_name="data">
            <div
              class="table-name-block"
              :class="{
                'child-block': data?.item?.child
              }"
            >
              <img
                v-if="data?.item?.__typename === 'BranchClassifModel'"
                src="/img/icons//settings/triangle.svg"
                class="mr-2"
                :class="{
                  'icon-triangle-no-active': !data?.item?.isOpenGroup,
                  'icon-triangle-active': data?.item?.isOpenGroup
                }"
                alt=""
                @click="opemGroup(data?.item, data?.index)"
              />
              <img
                v-if="data?.item?.__typename === 'BranchClassifModel'"
                src="/img/icons//settings/icon_group.svg"
                class="mr-2"
                alt=""
              />
              <img
                v-if="data?.item?.__typename === 'Branch'"
                src="/img/icons//settings/icon_branch.svg"
                class="mr-2"
                alt=""
              />
              <div>{{ data.item.name }}</div>
            </div>
          </template>

          <template #body_address="{ item }">
            <div class="table-addres-block">
              <div v-if="item?.address?.country">{{ item?.address?.country }}</div>
              <div v-if="item?.address?.city">{{ `, ${item?.address?.city}` }}</div>
              <div v-if="item?.address?.street">{{ `, ${item?.address?.street}` }}</div>
              <div v-if="item?.address?.house">{{ `, ${item?.address?.house}` }}</div>
              <div v-if="item?.address?.apartment">{{ `, ${item?.address?.apartment}` }}</div>
            </div>
          </template>

          <template #body_cancel_date="{ item }">
            <div>
              {{ item.subscription?.cancel_date ? formatDate(new Date(item.subscription?.cancel_date)) : '' }}
            </div>
          </template>
        </resizable-table>

        <custom-navbar
          class="table-navbar"
          :items="selected"
          :item_name="checkboxType === null ? 'Позиция' : checkboxType === 'Branch' ? 'Магазин' : 'Группа'"
          @clear="clearSelected"
        >
          <template #buttons>
            <b-button
              v-if="checkboxType === 'BranchClassifModel'"
              variant="dark"
              @click="deleteGroup"
            >
              Удалить группу
            </b-button>

            <b-button
              v-if="checkboxType === 'BranchClassifModel' && selected.length === 1"
              variant="dark"
              @click="changeNameGroup"
            >
              Переименовать группу
            </b-button>

            <b-dropdown
              v-if="checkboxType === 'Branch'"
              dropup
              no-caret
              class="navbar-dropdown"
              variant="dark"
            >
              <template #button-content>Добавить в группу</template>
              <b-dropdown-item
                v-for="item of classifList"
                :key="item.id"
                @click="addInGroup(item.id)"
              >
                <div>{{ item.name }}</div>
              </b-dropdown-item>
              <b-dropdown-item @click="createGroup">
                <div class="btn-add-group">
                  <img
                    src="/img/icons//settings/Plus.svg"
                    class="mr-2"
                    alt=""
                  />
                  <div>Создать</div>
                </div>
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-if="checkboxType === 'Branch'"
              variant="dark"
              @click="delFromGroup"
            >
              Убрать из группы
            </b-button>

            <b-button
              v-if="checkboxType === 'Branch'"
              variant="dark"
              @click="openPayModal"
            >
              Оплата
            </b-button>
          </template>
        </custom-navbar>
      </div>
    </div>
  </div>
</template>

<script>
  import PayBranchesModal from '../modal/PayBranchesModal.vue'
  import { EventBus } from '@/main'
  import { mapActions, mapGetters } from 'vuex'
  import ChangeNameGroupBranchModal from '../modal/ChangeNameGroupBranchModal.vue'
  import ResizableTable from '@/components/ResizableTable.vue'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar'
  import formatDate from '../../../utils/formatDate'
  export default {
    components: {
      ResizableTable,
      CustomNavbar,
      ChangeNameGroupBranchModal,
      PayBranchesModal
    },

    apollo: {
      BranchClassif: {
        query: require('../gql/BranchClassif.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.classifList = data?.BranchClassif?.classif ?? []
          const branches = data?.BranchClassif?.branches ?? []
          this.classifList.forEach((el) => (el.isOpenGroup = false))
          this.items = [...this.classifList, ...branches] ?? []
          this.isLoading = false
          if (this.$route.params.needPay) {
            for (const br of [...this.classifList.flatMap((el) => el.branches), ...branches]) {
              console.log(br)
              if (new Date(br?.subscription?.cancel_date).getTime() - new Date().getTime() < 7 * 24 * 60 * 60 * 1000)
                this.addSelected(true, br)
            }
            this.$nextTick(() => {
              this.openPayModal()
            })
          }
        },
        error(error) {
          this.isLoading = false
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {
        idGroup: null,
        classifList: [],
        checkboxType: null,
        items: [],
        company_name: '',
        selected: [],
        isLoading: true,
        select_all: false,
        search: '',
        fields: [
          {
            key: 'id',
            checked: true,
            label: '',
            isRowHeader: true,
            width: 50
          },
          {
            key: 'name',
            checked: true,
            label: 'Наименование магазина',
            width: 300
          },
          {
            key: 'address',
            checked: true,
            label: 'Адрес магазина',
            width: 400
          },
          {
            key: 'cancel_date',
            checked: true,
            label: 'Дата окночания подписки',
            width: 100
          }
        ]
      }
    },

    computed: {
      ...mapGetters({
        branch_list: 'settings/getBranchList',
        user: 'core/getUserInfo'
      })
    },

    watch: {
      items(newItems) {
        let currentSelectedAfterPagination = newItems.filter((item) => {
          return this.selected.some((el) => item.id === el.id)
        })
        if (this.items.length === 0) {
          this.select_all = false
        } else if (currentSelectedAfterPagination.length === this.items.length) {
          this.select_all = true
        } else {
          this.select_all = false
        }
      }
    },

    created() {
      EventBus.$on('refetchBranchClassif', () => {
        this.refetch()
      })
    },

    destroyed() {
      EventBus.$off('refetchBranchClassif', () => this.refetch())
    },

    methods: {
      formatDate,
      ...mapActions({
        get_companies: 'settings/getCompanies',
        update_copmany_name: 'core/updateCompnayName'
      }),

      openPayModal() {
        this.$bvModal.show('pay-branches-modal')
      },

      rowClick(item) {
        if (item.__typename === 'BranchClassifModel') return
        this.$router.push(`/settings/edit/${item.id}`)
      },

      refetch() {
        this.selected = []
        this.checkboxType = null
        this.$apollo.queries.BranchClassif.refetch()
      },

      createGroup() {
        this.$bvModal.show('add-group-branch-modal')
      },

      async addInGroup(id) {
        const listBranches = []
        this.selected.forEach((el) => {
          listBranches.push({ id: el, group: false })
        })
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/AddToBranchClassif.gql'),
            variables: {
              group: id,
              list: listBranches
            }
          })
          this.$noty.show(`Успешно`)
          EventBus.$emit('refetchBranchClassif')
        } catch (err) {
          console.log(err)
          this.$noty.error('Ошибка')
        }
      },

      async delFromGroup() {
        const listBranches = []
        this.selected.forEach((el) => {
          listBranches.push({ id: el, group: false })
        })
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/DelFromBranchClassif.gql'),
            variables: {
              list: listBranches
            }
          })
          this.$noty.show(`Успешно`)
          EventBus.$emit('refetchBranchClassif')
        } catch (err) {
          console.log(err)
          this.$noty.error('Ошибка')
        }
      },

      async deleteGroup() {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/DelBranchClassif.gql'),
            variables: {
              ids: this.selected
            }
          })
          this.$noty.show(`Успешно`)
          EventBus.$emit('refetchBranchClassif')
        } catch (err) {
          console.log(err)
          this.$noty.error('Ошибка')
        }
      },

      async changeNameGroup() {
        this.idGroup = this.selected[0]
        this.$bvModal.show('change-name-group-branch-modal')
      },

      opemGroup(item, index) {
        item.isOpenGroup = !item.isOpenGroup
        this.$set(this.items, index, item)

        const branches = item.branches ?? []
        if (item.isOpenGroup) {
          branches.forEach((el) => {
            el.__typename = 'Branch'
            el.child = true
          })
          this.items.splice(index + 1, 0, ...branches)
        } else {
          this.items.splice(index + 1, branches.length)
        }
      },

      addSelected(val, item) {
        if (val) {
          this.checkboxType = item?.__typename
          this.selected = [...this.selected, item?.id]
        } else {
          this.selected = this.selected?.filter((el) => el !== item?.id)
        }
        let currentSeleted = this.items.filter((item) => {
          return this.selected.some((el) => item.id === el)
        })
        if (currentSeleted.length < this.items.length) this.select_all = false
        if (currentSeleted.length === this.items.length) this.select_all = true
        if (currentSeleted.length === 0) this.checkboxType = null
      },

      clearSelected() {
        this.select_all = false
        this.selected = []
      },

      showSortModal() {
        this.$bvModal.show('sort-table-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
  .table-text-left {
    text-align: left;
  }

  .btn-add-group {
    display: flex;
    align-items: center;
  }

  .icon-triangle-no-active {
    transform: rotate(0deg);
  }

  .icon-triangle-active {
    transform: rotate(90deg);
  }

  .table-name-block {
    display: flex;
  }

  .table-addres-block {
    display: flex;
  }

  .child-block {
    margin-left: 45px;
  }
  .table-navbar {
    position: absolute;
    bottom: 10px;
    right: 20px;
    left: 20px;
  }

  .table-block {
    margin-top: 16px;
    background: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
    }
  }

  :deep .logo-cont {
    justify-content: center;
  }
  .table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow: scroll;
    //height: 510px;

    :deep th {
      height: 60px;
      color: var(--text-secondary-gray-400, #888) !important;
    }
  }

  .b-status {
    width: 2px;
    height: 32px;
    position: absolute;
    left: 0px;
    top: 50%;
    background: #bcbcbc;
    border-radius: 0px 1px 1px 0px;
    transform: translate(0%, -50%);

    &.success {
      background: #00cb91;
    }
  }

  .search-block {
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    border: 1px solid var(--gray-gray-150, #e1e1e1);
    border-radius: 2px;
    border-bottom: none;

    &__img {
      position: absolute;
      z-index: 10;
      top: 10px;
      left: 10px;
    }

    &__input {
      border-radius: 2px;
      border: 1px solid #bcbcbc;
      width: 300px;
      height: 32px;
      padding-left: 28px;
    }
    &__input::placeholder {
      color: var(--text-placeholder-gray-300, #bcbcbc);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__btn-clear-search-str {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;
      background: #efefef;
      padding: 0;

      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 8px;
        background: #888888;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%) rotate(45deg);
      }
    }
  }
</style>
